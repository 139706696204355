<template>
    <div class="container q-px-xl">
        <div>
            <img style="width: 100%;" src="/img/gfx/3cats.svg" />
        </div>
        <div>
            <div class="text-center">
                <h3>Benvenuto su FaunaLife</h3>
            </div>
        </div>
        <div class ="text-justify">
            Ogni animale è un individuo, con un passato e un futuro. 
            Grazie a FaunaLife la sua identità, provenienza e storia sono custoditi in Blockchain.
            <p>
                FaunaLife è un'anagrafe animale universale che rende possibile, per ogni animale:
                <ul>    
                    <li>avere una vera identità digitale</li>
                    <li>ricostruire il suo albero genealogico (laddove possibile)</li>
                    <li>fornire dati per la prevenzione di malattie ereditarie</li>
                    <li>semplificare la burocrazia</li>
                    <li>viaggiare con lui con maggior sicurezza e semplicità</li>
                    <li>avere sempre a disposizione i suoi dati</li>
                    <li>ritrovarlo con maggior rapidità in caso di smarrimento</li>
                    <li>contrastare più efficacemente truffe e maltrattamenti</li>
                </ul>
            </p>
        </div>
        <div class="devinfo">
            {{version}}
        </div>  
    </div>
</template>

<script>
    import * as config from '../common/config'
    export default {
        name : 'About',
        components: {},
        props : {},
        data () {            // State(s) function
            return {
                version : config.AppVersion
            }
        }
    }
</script>
